<template>
  <div class="transacoes-container">
    
    <!-- -->
      <section class="container-fluid" style="background:#F9F7EA;">

        <div class="row">
            
            <div class="col-sm-12 px-lg-5">
            <br>
                      <h3 class="txt-titulo-roxo bold" >Meus pagamentos</h3>
                      <p class="txt-paragrafo-roxo">
                        Abaixo você pode visualizar todas as suas transações mais recentes, além de acompanhar o status da sua compra.<br>
                        Se você acabou de realizar seu pagamento; Dependendo do método escolhido pode haver um atraso até que o serviço de pagamento
                        autorize e atualize o status do mesmo.<br><br>

                        <strong>*Importante:</strong> Após a transação ser aprovada é nescessário fazer o seu "Log-in" novamente para efetivar a extensão da sua assinatura.

</p>

<br><br>


<!-- table -->
<div class="table-responsive">
<table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col">Data</th>
      <th scope="col">Assinatura</th>
      <th scope="col">Método de pagamento</th>
      <th scope="col">Status</th>
      <th scope="col">Preço</th>
      
       
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in resultados" :key="'transacao'+index">
      
      <td>{{item.PAG_DATA}}</td>
      <td>{{item.PAG_PRODUTO}}</td>
      <td>{{item.PAG_METODO}}</td>
      <td>{{item.PAG_STATUS}}</td>
      <td>R$ {{item.PAG_PRECO}}</td>
      
     
    </tr> 
   
  </tbody>
</table>
</div>
<!-- -->

<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Nenhum pagamento encontrado...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS</button>
         </div>
        
        <br><br>


          </div>
                  
       </div>
                  
      </section>
<!-- -->



  </div>
</template>

<style>
.txt-titulo-roxo{color:#440D45;font-weight:bold;}
</style>

<script>
import servidor from '@/servidor'


export default {
  name: 'Transacoes',
  components: {},
  data(){
    return {
      semResultados:false,
      showLoading:false,
      resultados:[],
      filtrosBusca: new FormData(),
      texto:''
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }
  },
  methods:{
    
    buscarResultados(filtros){
       this.semResultados = false;
       this.resultados = [];
       this.showLoading = true;
       filtros.append('paginacao',0);
       fetch(servidor+'pagseguro/transacoes/'+ this.getUsuario,{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            this.resultados = data;
            this.showLoading = false;
          });
    },
    paginar(){
       this.semResultados = false;
       this.showLoading = true;
       this.filtrosBusca.append('paginacao',this.resultados.length);
       fetch(servidor+'pagseguro/transacoes/'+ this.getUsuario,{method:"POST", body: this.filtrosBusca})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            data.forEach(element => {
                this.resultados.push(element);
            });
            this.showLoading = false;
           
          });

    }
  },
  mounted(){
    let filtro = new FormData();
    filtro.append('paginacao',0);
    this.buscarResultados(filtro);
  }
}
</script>
